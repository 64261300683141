import { render, staticRenderFns } from "./ShowQuotaForm.vue?vue&type=template&id=3c454e75&"
import script from "./ShowQuotaForm.vue?vue&type=script&lang=js&"
export * from "./ShowQuotaForm.vue?vue&type=script&lang=js&"
import style0 from "./ShowQuotaForm.vue?vue&type=style&index=0&id=3c454e75&lang=sass&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/node/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3c454e75')) {
      api.createRecord('3c454e75', component.options)
    } else {
      api.reload('3c454e75', component.options)
    }
    module.hot.accept("./ShowQuotaForm.vue?vue&type=template&id=3c454e75&", function () {
      api.rerender('3c454e75', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/exporter/dialogs/dialogImproveQuota/components/ShowQuotaForm.vue"
export default component.exports