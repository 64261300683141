<template>
  <div class="quotaForm">
    <el-form :model="formRuleQuota" label-width="120px" @submit.native.prevent>
      <div class="quotaForm__time-content">
        <div class="quotaForm__time-wrap">
          <div class="quotaForm__input-label">
            <span>{{ isMobile ? 'Дата начала' : 'с' }}</span>
          </div>

          <IqSelectDateForm
            v-model="formRuleQuota.date_from"
            type="date"
            format="dd.MM.yyyy"
            disabled
            size="large"
            :icon="isMobile"
            :width="datepickerWidth"
            :prefix="inputPrefix"
          />

          <div class="quotaForm__input-timeLabel">
            <span>{{ isMobile ? 'Время начала' : '-' }}</span>
          </div>

          <IqSelectTimeForm
            v-model="formRuleQuota.time_from"
            :picker-options="{
              start: '00:00',
              step: '01:00',
              end: '24:00',
            }"
            size="large"
            disabled
            :prefix="inputPrefix"
            :width="timerWidth"
            :icon="isMobile"
          />
        </div>

        <div class="quotaForm__time-wrap">
          <div class="quotaForm__input-label">
            <span>{{ isMobile ? 'Дата окончания' : 'по' }}</span>
          </div>

          <IqSelectDateForm
            v-model="formRuleQuota.date_to"
            type="date"
            format="dd.MM.yyyy"
            disabled
            size="large"
            :icon="isMobile"
            :width="datepickerWidth"
            :prefix="inputPrefix"
          />

          <div class="quotaForm__input-timeLabel">
            <span>{{ isMobile ? 'Время окончания' : '-' }}</span>
          </div>

          <IqSelectTimeForm
            v-model="formRuleQuota.time_to"
            :picker-options="{
              start: '00:00',
              step: '01:00',
              end: '24:00',
            }"
            size="large"
            disabled
            :prefix="inputPrefix"
            :width="timerWidth"
            :icon="isMobile"
          />
        </div>
      </div>

      <multiple-form-item
        field="cultures_ids"
        label="Культура"
        message="Выберите культуру"
        :items="formRuleQuota.cultures_ids"
        :list="preparedCultureList"
        disabled
      />

      <div class="exporter__select">
        <iq-select-form
          v-model="formRuleQuota.exporter_id"
          disabled
          :options="exportersList"
          full-width
          label="Экспортер"
          key-label="name"
          key-value="id"
          label-width="118px"
          class="mr-40"
        />
      </div>

      <el-collapse v-model="showTruckTypes" class="m-b-20">
        <el-collapse-item name="1">
          <template slot="title">
            <div class="quotaForm__collapse-head">
              <div class="quotaForm__collapse-title">Настроить тип авто</div>
              <div class="quotaForm__collapse-subtitle">
                (По умолчанию: любые)
              </div>
            </div>
          </template>
          <el-form-item>
            <el-checkbox-group
              v-model="formRuleQuota.truck_type"
              disabled
              class="truck-types"
            >
              <el-checkbox
                v-for="type in autoTypes"
                :key="type.id"
                :label="type.val"
              >
                {{ type.name }}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-collapse-item>
      </el-collapse>
    </el-form>
  </div>
</template>

<script>
import { GET_CULTURE_FROM_STATE } from '@/views/control/store/actions'
import { GET_IS_MOBILE, GET_TERMINAL_CURRENT_ID_SLUG } from '@/store/actions'
import { GET_TIME } from '@/constants/date'
import { GET_USER_EXPORTERS } from '@/views/exporter/store/actions'
import { autoType } from '@/constants/auto-type'
import { getFinishTzDate, getTime, getTimeTo, getTzDate } from '@/core'
import { mapGetters } from 'vuex'
import IqSelectDateForm from '@/views/ui/components/select/IqSelectDateForm'
import IqSelectForm from '@/views/ui/components/select/IqSelectForm'
import IqSelectTimeForm from '@/views/ui/components/select/IqSelectTimeForm'
import MultipleFormItem from '@/UI/form/formItem/MultipleFormItem'

export default {
  name: 'CreateQuotaForm',
  components: {
    IqSelectForm,
    IqSelectTimeForm,
    IqSelectDateForm,
    MultipleFormItem,
  },
  props: {
    quota: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      autoTypes: autoType.slice(1),
      preparedCultureList: [],
      formRuleQuota: {
        cultures_ids: [undefined],
        exporter_id: undefined,
        date_from: null,
        date_to: null,
        time_from: '',
        time_to: '',
        truck_type: [],
      },
      showTruckTypes: '',
    }
  },

  computed: {
    ...mapGetters({
      terminalSlug: GET_TERMINAL_CURRENT_ID_SLUG,
      cultureList: GET_CULTURE_FROM_STATE,
      exportersList: GET_USER_EXPORTERS,
      isMobile: GET_IS_MOBILE,
    }),
    allTruckTypesOptions() {
      return autoType.slice(1).map(item => item.val)
    },

    datepickerWidth() {
      return this.isMobile ? '100%' : '135px'
    },
    timerWidth() {
      return this.isMobile ? '100%' : '100px'
    },
    inputPrefix() {
      return this.isMobile ? 'none' : ''
    },
    inputFormMR() {
      return window.innerWidth < '550' ? '' : 'mr-40'
    },
  },
  watch: {
    quota() {
      this.initQuotaData()
    },
    cultureList: {
      handler(value) {
        let sortedList = [...value]

        sortedList = sortedList.sort((a, b) => a.name.localeCompare(b.name))
        this.preparedCultureList = sortedList.map(item => ({
          id: item.id,
          unload_id: item.unload_id,
          name: `${item.name} ${item.harvest_year ? item.harvest_year : ''}`,
          harvest_year: `${item.harvest_year}`,
        }))
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.initQuotaData()
  },
  methods: {
    initQuotaData() {
      const newVal = this.quota

      if (Object.keys(this.quota).length) {
        this.setFormFields(
          newVal.cultures_ids,
          getTzDate(newVal.time_from),
          getFinishTzDate(newVal.time_to),
          getTime(newVal.time_from, GET_TIME),
          getTimeTo(newVal.time_to, GET_TIME),
          !newVal.truck_type_codes.length ||
            newVal.truck_type_codes.length === autoType.length - 1
            ? this.allTruckTypesOptions
            : newVal.truck_type_codes,
          newVal.exporters_ids[0],
        )
      } else {
        this.setFormFields()
      }
    },
    setFormFields(
      cultures_ids = [undefined],
      date_from = null,
      date_to = null,
      time_from = '',
      time_to = '',
      truck_type = this.allTruckTypesOptions,
      exporter_id = undefined,
    ) {
      this.formRuleQuota.cultures_ids = cultures_ids
      this.formRuleQuota.date_from = date_from
      this.formRuleQuota.date_to = date_to
      this.formRuleQuota.time_from = time_from
      this.formRuleQuota.time_to = time_to
      this.formRuleQuota.truck_type = truck_type
      this.formRuleQuota.exporter_id = exporter_id
    },
  },
}
</script>

<style lang="sass">
.quotaForm
  position: relative

  &__time-content
    display: flex
    justify-content: flex-start
    align-items: center
    margin-bottom: 20px

  &__time-wrap
    display: flex
    justify-content: space-between
    align-items: center
    flex: 0 1 50%
    & + &
      margin-left: 10px

  &__input-wrap
    margin-bottom: 0 !important
    .el-form-item__content
      margin-left: 0 !important

  &__input
    &--date.el-input
      margin-right: 10px
      width: 130px
    &--time.el-input
      width: 95px

  .autoInHour
    margin-bottom: 22px !important

  &__count-wrap
    .el-form-item__label
    width: auto !important
    .el-form-item__content
      margin-left: 0 !important

  &__collapse-title
    font-size: 20px
    line-height: 1
    margin-bottom: 5px
    color: #606266

  &__collapse-subtitle
    line-height: 1
    color: #606266

  .el-collapse-item__content
    padding: 10px 0

    .el-form-item
      margin-bottom: 0

    .el-form-item__content
      margin-left: 0 !important

    .el-radio-group
      display: flex
      justify-content: flex-start
      align-items: flex-start
      flex-wrap: wrap
      .el-radio
        flex: 0 0 100%
        margin: 0 0 10px 0
        display: flex
        flex-direction: row-reverse
        justify-content: space-between
        &.is-bordered.is-checked
          border-color: $color-blue-light

    .el-radio__input.is-checked .el-radio__inner
      border-color: $color-blue-light
      background: $color-blue-light

    .el-radio__input.is-checked+.el-radio__label
      color: $color-blue-light

  .el-select .el-input.is-disabled .el-input__inner
    background-color: #F5F7FA !important
    border-color: #E4E7ED !important
    color: #C0C4CC !important
    cursor: not-allowed

  .iq-date-picker.el-form-item,
  .iq-time-picker
    margin-bottom: 0 !important
  .mr-40
    margin-right: 40px

  .truck-types
    display: flex
    flex-direction: column

  &__disabled
    position: absolute
    width: 100%
    height: 100%
    z-index: 50

@media (max-width: 1199px)
  .quotaForm
    &__time-content
      flex-direction: column
      .el-input--suffix .el-input__inner
        padding-left: 12px
    &__time-wrap
      flex-direction: column
      align-items: flex-start
      column-gap: 6px
      width: 100%
    .dialog-add-quota__time-wrap + .dialog-add-quota__time-wrap
      margin-left: 0
    &__time-wrap:first-child
      margin-bottom: 14px
    .el-form-item.iq-date-picker, .el-form-item.iq-time-picker
      width: 100% !important
      margin-bottom: 17px !important
    &__input-label, &__input-timeLabel
      margin-bottom: 6px
    &__input-label
      min-width: 20px
  .add-rule-culture__btns
    justify-content: center
    flex-direction: column
    .el-button
      flex: 1
    .el-button + .el-button
      margin: 10px 0 0

.exporter__select
  margin-bottom: 20px
</style>
